// 全选-反选
export function checkAll(Allcheck,tableData,CarList) {

    // 使用 Map 来跟踪 CarList 中的唯一 car 对象
    const carMap = new Map(CarList.map(item => [item.id, item]));

    tableData.forEach((item) => {
        if (Allcheck) {
            // 如果全选状态为 true，则标记 check 为 true 并将 item 添加到 CarList
            item.check = true;
            if (!carMap.has(item.id)) {
                carMap.set(item.id, item);
            }
        } else {
            // 如果全选状态为 false，则从 CarList 中删除 item
            if (carMap.has(item.id)) {
                item.check = false;
                carMap.delete(item.id);
            }
        }
    });

    // 从 Map 中生成去重后的 CarList
    CarList = Array.from(carMap.values());
    console.log(CarList);
    return CarList
}