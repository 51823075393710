<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <div class="ct_box">
            <ul class="flex ul1">
                <li :class="title == item.id ? 'active' : ''" v-for="(item, index) in $store.state.UserMediaNav"
                    :key="index" @click="TabShow(item.id)">
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: '',
    created() {
        this.fullscreenLoading = true


        this.curlGet('/api/medium/init').then(res => {
            if (res.data.code) {
                this.$store.state.UserMedia = res.data.data
            }
        })
        this.curlGet('/api/medium_category/list').then(res => {
            if (res.data.code) {
                this.$store.state.UserMediaNav = res.data.data
                this.$store.state.UserTypeItem = res.data.data[0]
            }
        })
        this.fullscreenLoading = false
    },
    props: {
        title: String,
    },
    computed: {
        ...mapState(['UserMediaNav', 'UserMedia', 'UserTypeItem', 'FillForm'])
    },
    data() {
        return {
            Ctitle: '',
            fullscreenLoading: false,
        }
    },
    methods: {
        TabShow(id) {
            // if (id) {
            this.$store.state.UserTypeItem = id
            // }
            switch (this.$store.state.UserTypeItem) {
                case 1:
                    this.$router.push({
                        path: '/user/userMedia/News',
                    })
                    break;
                case 2:
                    this.$router.push({
                        path: '/user/userMedia/UserWeMedia'
                    })
                    break;
                case 3:
                    this.$router.push({
                        path: '/user/userMedia/UserPaperMedia'
                    })
                    break;
                case 4:
                    this.$router.push({
                        path: '/user/userMedia/UserOfficialAccounts'
                    })
                    break;
                case 5:
                    this.$router.push({
                        path: '/user/userMedia/UserWb'
                    })
                    break;
                case 6:
                    this.$router.push({
                        path: '/user/userMedia/UserRedBook'
                    })
                    break;
                case 7:
                    this.$router.push({
                        path: '/user/userMedia/UserShortVideo'
                    })
                    break;
                default:
                    break;
            }
        }
    },
}
</script>

<style lang=scss scoped>
.ul1 {
    padding: 1rem 0;
    align-items: center;

    li {
        padding: .5rem 0.7rem;
        margin-right: 15px;
        font-size: 1.2rem;
        transition: 0.2s all;
        border-radius: 0.5rem;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:hover {
            color: #168ad9;
        }
    }
}

// 被选中
.active {
    background: #108CDD;
    color: #fff !important;
    font-weight: 700;
}
</style>